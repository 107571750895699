import { useMutation, useQuery } from "@tanstack/react-query";
import React, { useMemo } from "react";
import useFetch from ".././Hooks/useFetch";
import { Endpoints } from "../Configs";
import { useLocation, useNavigate } from "react-router-dom";
import CustomToast from "../Utils/CustomToast";
import { useDispatch } from "react-redux";
import { saveAccessToken, saveUserInfo, setTemptoken } from "../ReduxState/Slices/UserSlice";
import { RouteNames } from "../Routes";


export const useRegister=()=>{
    const navigate = useNavigate();
    const configs = useMemo(()=>{
        return {
            endpoint : Endpoints.Register,
            method : 'POST'
        }
    },[]);
    const {fetchData} = useFetch();
    return useMutation({
        mutationKey : ["signup"],
        mutationFn : (body)=> fetchData({...configs, body}),
        onSuccess : (data)=>{
            // CustomToast.Error();
            if(data.status){
                navigate('/verify-email',{state : {...data}});
            }
        },
        onError : (error)=>{
        }
    })
}

export const useLogin=()=>{
    const navigate = useNavigate();
    const dispath = useDispatch();
    const configs = useMemo(()=>{
        return {
            endpoint : Endpoints.Login,
            method : 'POST'
        }
    },[]);
    const {fetchData} = useFetch();
    return useMutation({
        mutationKey : ["login"],
        mutationFn : (body)=> fetchData({...configs, body}),
        onSuccess : (response)=>{
            if(response?.user?.user_type=="customer"){
                CustomToast.Error('Customer can only login from mobile app');
                return;
            }
            if(response?.tempToken){
                dispath(setTemptoken(response?.tempToken));
                navigate(RouteNames.AddBusinessDetails);
            }
            else{
                dispath(saveAccessToken(response.token));
                dispath(saveUserInfo(response));
            }
        },
        onError : (err)=>{
            if(err?.goto=="email_verification"){
                navigate('/verify-email',{state : {...err}});
            }
            else if(err?.goto=="phone_verification"){
                navigate(RouteNames.VerifyPhone,{state : {...err}});
            }
        }
    })
}

export const useVerifyRegisterEmail=()=>{
    const navigate = useNavigate();
    const {state} = useLocation();
    const configs = useMemo(()=>{
        return {
            endpoint : Endpoints.VerifyRegisterEmail,
            method : 'POST'
        }
    },[]);
    const {fetchData} = useFetch();
    return useMutation({
        mutationKey : ["verify-register-email"],
        mutationFn : (body)=> fetchData({...configs, body}),
        onSuccess : (response)=>{
            navigate(RouteNames.VerifyPhone, {state})
            // navigate('/verify-phone',{state});
        }
    })
}

export const useResendEmailOTP=()=>{
    const configs = useMemo(()=>{
        return {
            endpoint : Endpoints.ResendEmailOTP,
            method : 'POST'
        }
    },[]);
    const {fetchData} = useFetch();
    return useMutation({
        mutationKey : ["resend_email_otp"],
        mutationFn : (body)=> fetchData({...configs, body}),
    })
}

// export const useSendPhoneOTP=(phone)=>{
//     const configs = useMemo(()=>{
//         return {
//             endpoint : Endpoints.SendOtpOnPhone,
//             method : 'POST'
//         }
//     },[]);
//     const {fetchData} = useFetch();
//     return useQuery({
//         queryKey : ["send_phone_otp"],
//         queryFn : () => fetchData({...configs, body : {phone: phone?.includes("+")? phone : "+" + phone}}),
//     })
// }

export const useSendPhoneOTP=()=>{
    const configs = useMemo(()=>{
        return {
            endpoint : Endpoints.SendOtpOnPhone,
            method : 'POST'
        }
    },[]);
    const {fetchData} = useFetch();
    return useMutation({
        mutationKey : ["send_phone_otp"],
        mutationFn : (phone) => fetchData({...configs, body : {phone: phone?.includes("+")? phone : "+" + phone}}),
        onSuccess:()=>{
            
        }
    })
}

export const useVerifyRegisterPhone=(
    // {isForgot}
    )=>{
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const configs = useMemo(()=>{
        return {
            endpoint : Endpoints.VerifyRegisterPhone,
            method : 'POST'
        }
    },[]);
    const {fetchData} = useFetch();
    return useMutation({
        mutationKey : ["verify-register-phone"],
        mutationFn : (body)=> fetchData({...configs, body}),
        onSuccess : (response,variable)=>{
            // if(response?.tempToken && !isForgot){
            if(response?.tempToken){
                dispatch(setTemptoken(response?.tempToken));
                navigate(RouteNames.AddBusinessDetails);
            }
            // else{
            //     navigate(
            //         RouteNames.UpdateForgotPassword,{
            //             state : {
            //                 ...variable
            //             }
            //     })
            // }
        }
    })
}

export const useForgotPassword=()=>{
    const navigate = useNavigate();
    const configs = useMemo(()=>{
        return {
            endpoint : Endpoints.ForgotPassword,
            method : 'POST'
        }
    },[]);
    const {fetchData} = useFetch();
    return useMutation({
        mutationKey : ["forgot-password"],
        mutationFn : (body)=> fetchData({...configs, body}),
        onSuccess : (response,variable)=>{
            navigate(
                RouteNames.VerifyForgotPassword,{
                    state : {
                        identity : variable.email_or_phone,
                        msg: response?.message
                    }
            })
            // if(response?.message=="Check your phone for confirmation Code!"){
            //     navigate(
            //         RouteNames.VerifyPhone,{
            //             state : {
            //                 identity : variable.email_or_phone
            //             }
            //     })
            // }
            // else{
            //     navigate(
            //         RouteNames.VerifyForgotPassword,{
            //             state : {
            //                 identity : variable.email_or_phone
            //             }
            //     })
            // }
        }
    })
}

export const useVerifyForgotEmail=()=>{
    const navigate = useNavigate();
    const configs = useMemo(()=>{
        return {
            endpoint : Endpoints.ForgotPasswordVerification,
            method : 'POST'
        }
    },[]);
    const {fetchData} = useFetch();
    return useMutation({
        mutationKey : ["verify-forgot-password"],
        mutationFn : (body)=> fetchData({...configs, body}),
        onSuccess : (response,variable)=>{
            navigate(
                RouteNames.UpdateForgotPassword,{
                    state : {
                        ...variable,
                        ...response
                    }
            })
        }
    })
}

export const useUpdateForgotPassword=()=>{
    const navigate = useNavigate();
    const configs = useMemo(()=>{
        return {
            endpoint : Endpoints.ForgotPasswordUpdate,
            method : 'POST'
        }
    },[]);
    const {fetchData} = useFetch();
    return useMutation({
        mutationKey : ["update-forgot-password"],
        mutationFn : (body)=> fetchData({...configs, body}),
        onSuccess : ()=>{
            navigate(RouteNames.Login)
        }
    })
}

export const useCountriesList=()=>{
    const configs = useMemo(()=>{
        return {
            endpoint : Endpoints.CountriesList
        }
    },[]);
    const {fetchData} = useFetch();
    return useQuery({
        queryKey : ["countries_list"],
        queryFn : ()=> fetchData({...configs}),
        select : (res)=>{
            return res?.data?.map((item,index)=>{
                return({
                    label : item.name,
                    value : item.id
                })
            }) || []
        }
    })
}

export const useGoogleSignin=()=>{
    const navigate = useNavigate();
    const dispath = useDispatch();
    const configs = useMemo(()=>{
        return {
            endpoint : Endpoints.GoogleLogin,
            method : 'POST'
        }
    },[]);
    const {fetchData} = useFetch();
    return useMutation({
        mutationKey : ["google-login"],
        mutationFn : (body)=> fetchData({...configs, body}),
        onSuccess : (response)=>{
            if(response?.user?.user_type=="customer"){
                CustomToast.Error('Customer can only login from mobile app');
                return;
            }
            if(response?.tempToken){
                dispath(setTemptoken(response?.tempToken));
                navigate(RouteNames.AddBusinessDetails);
                return;
            }
            if(response?.token){
                dispath(saveAccessToken(response.token));
                dispath(saveUserInfo(response));
            }
            
        },
        onError : (err,variables)=>{
            console.log('errd___',err);
            console.log('variables__',variables);
            if(err?.goto=="email_verification"){
                navigate('/verify-email',{state : {...err}});
            }
            else if(err?.goto=="phone_verification"){
                navigate(RouteNames.VerifyPhone,{state : {...err}});
            }
            else{
                navigate(RouteNames.AddMobile,{state : {...variables}});
            }
        }
    })
}

export const useAppleSignin=()=>{
    const navigate = useNavigate();
    const dispath = useDispatch();
    const configs = useMemo(()=>{
        return {
            endpoint : Endpoints.AppleLogin,
            method : 'POST'
        }
    },[]);
    const {fetchData} = useFetch();
    return useMutation({
        mutationKey : ["apple-login"],
        mutationFn : (body)=> fetchData({...configs, body}),
        onSuccess : (response)=>{
            if(response?.user?.user_type=="customer"){
                CustomToast.Error('Customer can only login from mobile app');
                return;
            }
            if(response?.tempToken){
                dispath(setTemptoken(response?.tempToken));
                navigate(RouteNames.AddBusinessDetails);
                return;
            }
            if(response?.token){
                dispath(saveAccessToken(response.token));
                dispath(saveUserInfo(response));
            }
            
        },
        onError : (err,variables)=>{
            console.log('errd___',err);
            console.log('variables__',variables);
            if(err?.goto=="email_verification"){
                navigate('/verify-email',{state : {...err}});
            }
            else if(err?.goto=="phone_verification"){
                navigate(RouteNames.VerifyPhone,{state : {...err}});
            }
            else{
                navigate(RouteNames.AddMobile,{state : {...variables}});
            }
        }
    })
}