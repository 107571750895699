import { createSlice } from '@reduxjs/toolkit';


const userSlice = createSlice({
    name : 'user',
    initialState : {
        userInfo : null,
        access_token : null,
        previewData : null,
        tempToken : null,
        language : 'English'
    },
    reducers : {
        saveUserInfo : (state,action)=>{
            state.userInfo = action.payload
        },
        saveAccessToken : (state,action)=>{
            state.access_token = action.payload
        },
        clearData : (state,action)=>{
            state.userInfo = null;
            state.access_token = null;
        },
        savePreviewData : (state,action)=>{
            state.previewData = action.payload;
        },
        clearPreviewData : (state)=>{
            state.previewData = null;
        },
        setTemptoken : (state,action)=>{
            state.tempToken = action.payload
        },
        _setLanguage : (state,action)=>{
            state.language = action.payload
        }
        
    }
})

export const {
    saveUserInfo,
    saveAccessToken,
    clearData,
    clearPreviewData,
    savePreviewData,
    setTemptoken,
    _setLanguage
} = userSlice.actions;

export default userSlice.reducer;