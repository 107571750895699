import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AccessToken_, SelectedLanguage_, UserInfo_ } from "../ReduxState/ReducerHelpers";
import { _setLanguage, clearData } from "../ReduxState/Slices/UserSlice";
import { RouteNames } from "../Routes";
import useCustomTranslation from "../Hooks/useCustomTranslation";
import { values } from "../App";


export default function Header() {
    const { t } = useCustomTranslation();
    const navigate = useNavigate();
    const token = useSelector(AccessToken_);
    const userInfo = useSelector(UserInfo_);
    const dispatch = useDispatch();
    const closeRef = useRef();
    const location = useLocation();

    const handleLogout = (e, toggle) => {
        e.preventDefault();
        dispatch(clearData());
        !toggle && closeRef.current.click()
    }

    const selectedLanguage = useSelector(SelectedLanguage_) || "English";
    console.log('seleHeader__', selectedLanguage);
    const [language, setLanguage] = useState(selectedLanguage);

    const handleSave = () => {
        dispatch(_setLanguage(language));
        window.location.reload();
    }

    useEffect(() => {
        if (language != selectedLanguage) {
            handleSave()
        }
    }, [language])

    const links = useMemo(() => {
        return ([
            {
                title: 'About Us',
                page: RouteNames.About
            },
            {
                title: 'Why Choose LAND?',
                page: RouteNames.WhyLand
            },
            {
                title: 'How LAND Works',
                page: RouteNames.HowLandWorks
            },
            {
                title: 'Help',
                page: RouteNames.Help
            },
            {
                title: 'Contact Us',
                page: RouteNames.Contact
            },
            {
                title: 'Services',
                page: RouteNames.Services
            }
        ])
    }, []);
    if (token) {
        return (
            <div className="header">
                <div className="container py-3">
                    <div className="row align-items-center">
                        <div className="col-xl-4 col-lg-3 col-md-2 col-sm-2 col-2 d-none d-md-block">
                            {/* <a href="#" className="toggle" data-bs-toggle="collapse" data-bs-target="#nav_area">
                                <img src="/assets/img/toggle.svg" alt="" />
                            </a> */}
                            <div className="nav_area collapse" id="nav_area">
                                <Link ref={closeRef} className="toggle close medium pb-4 d-inline-block text-darkgray" data-bs-toggle="collapse" data-bs-target="#nav_area">
                                    <i className="fa-sharp fa-light fa-circle-chevron-left"></i> {t("Close")}
                                </Link>
                                <ul className="menu text-end">
                                    {
                                        links.map((item, index) => {
                                            return (
                                                <li key={index}>
                                                    <Link onClick={(e) => {
                                                        e.preventDefault();
                                                        closeRef.current.click();
                                                        navigate(item.page);
                                                    }}>{t(`${item.title}`)}</Link>
                                                </li>
                                            )
                                        })
                                    }
                                    {
                                        token
                                            ?
                                            <li>
                                                <Link onClick={handleLogout}>{t("Logout")}</Link>
                                            </li>
                                            :
                                            null
                                    }
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-4 col-sm-4 text-xl-center text-end col-3">
                            <Link to={"/"}><img style={{ width: 120 }} src="/assets/img/logo.png" alt="" /></Link>
                        </div>
                        <div className="col-xl-5 col-lg-5 col-md-6 col-sm-6 col-9">
                            <div className="d-flex gap-lg-4 gap-2 align-items-center justify-content-end head-right">
                                <div style={{
                                    // backgroundColor: 'yellow',
                                    // width: '70px',
                                    // height: '50px'
                                    // padding: '10px'
                                }} className="d-flex align-items-center gap-1">
                                    <i className="fa-light fa-globe text-white"></i>
                                    <select
                                        defaultValue={language}
                                        onChange={(e) => {
                                            console.log(e.target.value);
                                            setLanguage(e.target.value)
                                        }}
                                        className="border-0 lang text-white bg-transparent medium lang-dropdown">
                                        <option
                                            value={'English'}
                                        >
                                            EN
                                        </option>
                                        <option
                                            value={'Arabic'}
                                        >
                                            AR
                                        </option>
                                        {/* <option>hindi</option> */}
                                    </select>
                                </div>



                                <div>
                                    <a href="#" className="d-block" id="head_profile" data-bs-toggle="dropdown" aria-expanded="false">
                                        <span className="fs-16 text-lightgray pe-2">{t("Hi")}, {userInfo?.user?.fullname}</span>
                                        {/* <img src="/assets/img/user.png" alt="" width="50" height="50" className="rounded-circle"/> */}
                                        <svg viewBox="0 0 24 24" width="20" height="20" stroke="#878787" className="transition" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round"><polyline points="6 9 12 15 18 9"></polyline></svg>
                                    </a>

                                    <div className="profile-dropdown dropdown-menu dropdown-menu-end p-0" aria-labelledby="head_profile" style={{ backgroundColor: '#0A0909' }}>
                                        <ul>
                                            <li className="pb-1">
                                                <Link to={RouteNames.Dashboard}>
                                                    <img src="/assets/img/icon/clipboardtext.svg" width="25" height="25" className="me-2 ms-2" alt="" />
                                                    {t("Listings")}
                                                </Link>
                                            </li>
                                            <li className="pb-1">
                                                <Link to={RouteNames.MyAccount}>
                                                    <img src="/assets/img/my-account.png" width="25" height="25" className="me-2 ms-2" alt="" />
                                                    {t("My Account")}
                                                </Link>
                                            </li>
                                            <li className="pb-1">
                                                <Link to={RouteNames?.PaymentHistory}>
                                                <img src="/assets/img/icon/dollarcircle.svg" width="25" height="25" className="me-2 ms-2" alt=""/>
                                                {t("Payment History")}
                                                </Link>
                                            </li>
                                            <li className="pb-1">
                                                <Link to={RouteNames.Bookings}>
                                                    <img src="/assets/img/check-square.png" width="25" height="25" className="me-2 ms-2" alt="" />
                                                    {t("Bookings")}
                                                </Link>
                                            </li>
                                            <li className="pb-1">
                                                <Link to={RouteNames.YachtBookingRequest}>
                                                    <img src="/assets/img/anchor.png" width="25" height="25" className="me-2 ms-2" alt="" />
                                                    {t("Booking Request")}
                                                </Link>
                                            </li>
                                            <li className="pb-1">
                                                <Link to={RouteNames.CalendarBooking}>
                                                    <img src="/assets/img/icon/calendar.svg" width="25" height="25" className="me-2 ms-2" alt="" />
                                                    {t("Calendar")}
                                                </Link>
                                            </li>
                                            <li className="pb-1">
                                                <Link to={RouteNames.ApiKeys}>
                                                    <img src="/assets/img/key.png" width="25" height="25" className="me-2 ms-2" alt="" />
                                                    {t("APIs & Keys")}
                                                </Link>
                                            </li>
                                            <li className="pb-4">
                                                <Link onClick={(e) => handleLogout(e, true)}>
                                                    <img src="/assets/img/icon/logout.svg" width="25" height="25" className="me-2 ms-2" alt="" />
                                                    {t("Log Out")}
                                                </Link>
                                            </li>
                                        </ul>

                                    </div>
                                </div>




                                <Link
                                    onClick={(e) => {
                                        e.preventDefault();
                                        console.log('vauesssS____', values);
                                    }}
                                    className="medium custom-btn d-none d-md-block">{t("Download App")} </Link>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
    return (
        <div className="header">
            <div className="container py-3">
                <div className="row align-items-center">
                    <div className="col-xl-4 col-lg-3 col-md-2 col-sm-2 col-2">
                        {/* <a href="#" className="toggle" data-bs-toggle="collapse" data-bs-target="#nav_area">
                            <img src="/assets/img/toggle.svg" alt="" />
                        </a> */}
                        <div className="nav_area collapse" id="nav_area" style={{}}>
                            <a ref={closeRef} href="#" className="toggle close medium pb-4 d-inline-block text-darkgray" data-bs-toggle="collapse" data-bs-target="#nav_area">
                                <i className="fa-sharp fa-light fa-circle-chevron-left"></i> {t("Close")}
                            </a>
                            <ul className="menu text-end">
                                {
                                    links.map((item, index) => {
                                        return (
                                            <li key={index}>
                                                <Link onClick={(e) => {
                                                    e.preventDefault();
                                                    closeRef.current.click();
                                                    navigate(item.page);
                                                }}>{item.title}</Link>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 text-xl-center text-end col-4">
                        <Link to={'/'}><img style={{ width: 120 }} src="/assets/img/logo.png" alt="" /></Link>
                    </div>
                    <div className="col-xl-4 col-lg-5 col-md-6 col-sm-6 col-6">
                        <div className="d-flex gap-lg-4 gap-2 align-items-center justify-content-end head-right">
                            <div className="d-flex align-items-center gap-1">
                                <i className="fa-light fa-globe text-white"></i>
                                <select
                                    defaultValue={language}
                                    onChange={(e) => {
                                        console.log(e.target.value);
                                        setLanguage(e.target.value)
                                    }}
                                    className="border-0 lang text-white bg-transparent medium lang-dropdown">
                                    <option
                                        value={'English'}
                                    >
                                        EN
                                    </option>
                                    <option
                                        value={'Arabic'}
                                    >
                                        AR
                                    </option>
                                    {/* <option>hindi</option> */}
                                </select>
                            </div>
                            {
                                !token && location?.pathname!="/verify-phone" 
                                && location?.pathname!="/verify-email"
                                && location?.pathname!="/add-business-details" 
                                    ?
                                    <div className="dropdown business-dropdown">
                                        <button className="border-0 text-white bg-transparent medium" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            {t("Business")}
                                        </button>
                                        <ul className="dropdown-menu">
                                            <Link className="dropdown-item fs-16 text-white py-2 bg-black" to={'/login'}>{t("Login")}</Link>
                                            <Link className="dropdown-item fs-16 text-white py-2 bg-black" to={'/signup'}>{t("Sign up")}</Link>
                                            {/* <li><a  className="dropdown-item fs-16 text-white py-2" href="login">Log In</a></li> */}
                                            {/* <li><a  className="dropdown-item fs-16 text-white py-2" href="#">Sign Up</a></li> */}
                                        </ul>
                                    </div>
                                    :
                                    null
                            }

                            <Link
                                onClick={(e) => {
                                    e.preventDefault();
                                    console.log('vauesssS____', values);
                                }}
                                className="medium custom-btn d-none d-md-block"
                                style={{fontSize: '16px', paddingInline: '12px'}}
                                >{t("Download App")}</Link>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}