import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { values } from "../App";

export default function useCustomTranslation(){
    const {t} = useTranslation();

    // const t = useCallback((value)=>{
    //     values[value] = value;
    //     return trans(value);
    // },[i18n.language]);

    // return {t};
    return {t};
}