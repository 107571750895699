export const Endpoints = {
    ServerUrl : process.env.REACT_APP_SERVER_URL,
    APIBaseUrl : process.env.REACT_APP_SERVER_URL,//+ '/api/v1/'
    Login : 'account/login/',
    Register : 'account/register/',
    VerifyRegisterEmail : 'account/otp_verify/',
    ResendEmailOTP : 'account/resend-otp/',
    VerifyRegisterPhone : 'account/verify-otp-phone/',
    SendOtpOnPhone : 'account/send-otp-on-phone/',
    // ForgotPassword : 'account/forgot-password/',
    ForgotPassword : 'account/forgot-password-web/',
    ForgotPasswordVerification : 'account/verify-code/',
    ForgotPasswordUpdate : 'account/new-password/',
    UpdatePassword : 'account/update-password/',
    ListingAll : 'listing/list/',
    CategoriesList : 'listing/categories/',
    BrandList : 'listing/brands/',
    CarTypes : 'listing/car-types/',
    AddListing : 'listing/create/',
    Details : 'listing/detail/',//+id
    DeleteImage : 'listing/image/delete/',//+id
    DeleteFeature : 'listing/feature/delete/',//+id
    UpdateItem : 'listing/update/',//+id
    DeleteItem : 'listing/delete/',//+id,
    AddBusinessDetails : 'account/update-business-detail/',
    RefreshToken : 'account/token/refresh/',
    CountriesList : 'account/countries/',
    UpdatePhoneSendOTP : 'account/update-phone-otp-send/',
    UpdatePhoneVerifyOTP : 'account/update-phone-otp-verify/',
    UpdateEmailSendOTP : 'account/update-email-otp-send/',
    UpdateEmailVerifyOTP : 'account/update-email-otp-verify/',
    BusinessProfile : 'account/business-profile',
    FuelTypes : 'listing/fuel-types/',
    AddBankDetails : 'account/add-update-bank/',
    BankDetails : 'account/bank-details/',
    GoogleLogin : 'account/gmail-login/',
    AppleLogin : 'account/apple-login/',
    GetBookings: '/listing/all-bookings',
    GetCancelledBookings: 'listing/cancelled-bookings',
    BookingDetail: 'listing/booking-summary/', //+id
    CancelBooking: 'listing/cancel-booking/',
    YachtPendingBookings: 'listing/yacht-bookings',
    AcceptYachtBooking: 'listing/accept-yacht-booking/',
    RejectYachtBooking: 'listing/reject-yacht-booking/',
    BlockCalendarBooking: 'listing/block-booking-date/',
    toggleListing: 'listing/toggle-visibility/',
    ApiKeys: 'account/business-apikeys',
    AddApiKey: 'account/add-business-apikey',
    DeleteApiKey: "account/delete-business-apikey/",
    PaymentHistory: "listing/payment-history",
    copyAPIkey: 'account/copy-business-apikey/',
    updateProfile: 'account/user-update/',
}