import React from "react";
import ClipLoader from 'react-spinners/ClipLoader';
import useCustomTranslation from "../Hooks/useCustomTranslation";


export default function Button({title, isLoading, onClick}){
    const {t} = useCustomTranslation();
    return(
        <button disabled={isLoading} type="submit" className={`custom-btn w-100 rounded-pill`} onClick={onClick} style={{zIndex : 1}}>
            {
            isLoading ? <ClipLoader className="custom-loader" color="white"/> : t(`${title}`)}
        </button>
    )
}