import { useSelector } from 'react-redux';
import * as Pages from '../Pages';
import { BrowserRouter, Routes, Route, Outlet, Navigate, useNavigate, useLocation } from 'react-router-dom';
import { Suspense, useEffect, useRef } from 'react';
import { AccessToken_, TempToken_, UserInfo_ } from '../ReduxState/ReducerHelpers';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import Forgot2 from '../Pages/Forgot2';
import Forgot3 from '../Pages/Forgot3';
import { useTranslation } from 'react-i18next';
// 000bde6b-8ade-4fc2-83ae-ee505e356f5b

function Root() {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const user = useSelector(UserInfo_);
    const PrevPath = useRef(pathname);

    if (PrevPath.current != pathname) {
        window.scrollTo(0, 0);
        PrevPath.current = pathname;
    }
    // if(pathname.includes(RouteNames.AddBusinessDetails) && user?.user?.business_detail){
    //     navigate(RouteNames.Dashboard);
    // }
    return (
        <Suspense fallback={<div style={{ backgroundColor: 'black', height: '100vh', width: '100vw' }}></div>}>
            <Header />
            <Outlet />
            {/* <Footer/> */}
        </Suspense>
    )
}
function WithAuth() {
    let token = useSelector(AccessToken_);
    const user = useSelector(UserInfo_);
    let navigate = useNavigate();

    useEffect(() => {
        if (!token) {
            navigate('/login');
        }
        // else if(!user?.user?.business_detail){
        //     navigate(RouteNames.AddBusinessDetails);
        // }
    })
    if (!token) {
        return null;
    }
    return (
        <Outlet />
    )
}

function CheckAuth() {
    let token = useSelector(AccessToken_);
    let navigate = useNavigate();

    useEffect(() => {
        if (token) {
            navigate('/dashboard');
        }
    })
    return (
        <Outlet />
    )
}

function CheckTempToken() {
    let temp_token = useSelector(TempToken_);
    let token = useSelector(AccessToken_);
    let navigate = useNavigate();

    useEffect(() => {
        if (!temp_token) {
            if (!token) {
                navigate(RouteNames.Login)
            }
            else {
                navigate(RouteNames.Dashboard)
            }
        }
    })
    if (!temp_token) {
        return null;
    }
    else {
        return (
            <Outlet />
        )
    }
}


// / => landing
// /login => login page
// /signup => signup page
// /dashboard => listing page
export function MainRoutes() {
    // const {i18n} = useTranslation();
    // useEffect(()=>{
    //     i18n.changeLanguage('ar');
    // },[]);
    return (
        <BrowserRouter>
            <Routes>
                <Route path="*" element={<div>Not Found</div>} />
                <Route path="" element={<Root />}>
                    <Route path="/" element={<Pages.Landing />} />

                    <Route path={RouteNames.Copyright} element={<Pages.Copyright />} />
                    <Route path={RouteNames.PrivacyPolicy} element={<Pages.PrivacyPolicy />} />
                    <Route path={RouteNames.DataProtection} element={<Pages.DataProtection />} />
                    <Route path={RouteNames.Cancellation} element={<Pages.CancellationPolicy />} />
                    <Route path={RouteNames.Cookies} element={<Pages.Cookies />} />
                    <Route path={RouteNames.Contact} element={<Pages.Contact />} />
                    <Route path={RouteNames.HowLandWorks} element={<Pages.HowLandWorks />} />
                    <Route path={RouteNames.Terms} element={<Pages.TermsConditions />} />
                    <Route path={RouteNames.WhyLand} element={<Pages.WhyLand />} />
                    <Route path={RouteNames.Services} element={<Pages.Services />} />
                    <Route path={RouteNames.About} element={<Pages.About />} />
                    <Route path={RouteNames.Help} element={<Pages.HelpPage />} />

                    <Route path="" element={<CheckAuth />}>
                        <Route path="/login" element={<Pages.Login />} />
                        <Route path="/signup" element={<Pages.Signup />} />
                        <Route path="/verify-email" element={<Pages.VerifyRegisterEmail />} />
                        <Route path="/verify-phone" element={<Pages.VerifyRegisterPhone />} />
                        <Route path="/forgot-password" element={<Pages.ForgotPassword />} />
                        <Route path={RouteNames.VerifyForgotPassword} element={<Pages.VerifyForgotPassword />} />
                        <Route path={RouteNames.UpdateForgotPassword} element={<Pages.UpdateForgotPassword />} />
                        <Route path={RouteNames.AddMobile} element={<Pages.AddMobile />} />

                        {/* <Route path="signup" element={<Pages./>} /> */}
                    </Route>
                    <Route path="" element={<CheckTempToken />}>
                        <Route path={RouteNames.AddBusinessDetails} element={<Pages.BusinessDetails />} />
                    </Route>
                    <Route path="" element={<WithAuth />}>
                        <Route path="dashboard" element={<Pages.Listing />} />
                        <Route path="add-listing" element={<Pages.AddListing />} />
                        <Route path={RouteNames.EditListing()} element={<Pages.EditListing />} />
                        <Route path={RouteNames.CarPreview} element={<Pages.CarPreview />} />
                        <Route path={RouteNames.LandJetPreview} element={<Pages.LandjetPreview />} />
                        <Route path={RouteNames.PrivateJetPreview} element={<Pages.PrivateJetPreview />} />
                        <Route path={RouteNames.YachtPreview} element={<Pages.YachtPreview />} />
                        <Route path={RouteNames.ChauffeursPreview} element={<Pages.ChaffeursPreview />} />
                        <Route path={RouteNames.Preview} element={<Pages.PreviewListing />} />
                        <Route path={RouteNames.SubscriptionPlans} element={<Pages.SubscriptionPans />} />
                        <Route path={RouteNames.Legal} element={<Pages.Legal />} />
                        <Route path={RouteNames.MakePayment} element={<Pages.MakePayment />} />
                        <Route path={RouteNames.PaymentSuccess} element={<Pages.PaymentSuccess />} />
                        {/* <Route path={"my-account"} element={<Pages.MyAccount/>}>
                    <Route path={"security"} element={<Pages.AccountSecurity/>} />
                </Route> */}
                        <Route path={RouteNames.MyAccount} element={<Pages.MyAccount />} />
                        <Route path={RouteNames.AccountSecurity} element={<Pages.AccountSecurity />} />
                        <Route path={RouteNames.ChangePassword} element={<Pages.UpdatePassword />} />
                        <Route path={RouteNames.ChangeEmail} element={<Pages.UpdateEmail />} />
                        <Route path={RouteNames.ChangeEmailVerify} element={<Pages.UpdateEmailVerifyOTP />} />
                        <Route path={RouteNames.ChangePhone} element={<Pages.UpdateEmail />} />
                        <Route path={RouteNames.ChangeName} element={<Pages.UpdateEmail />} />
                        <Route path={RouteNames.ChangePhoneVerify} element={<Pages.UpdateEmailVerifyOTP />} />
                        <Route path={RouteNames.BankDetails} element={<Pages.BankDetails />} />
                        <Route path={RouteNames.SelectLanguage} element={<Pages.SelectLanguage />} />
                        <Route path={RouteNames.Bookings} element={<Pages.Bookings />} />
                        <Route path={RouteNames.BookingDetail} element={<Pages.BookingDeatils />} />
                        <Route path={RouteNames.CalendarBooking} element={<Pages.CalendarBooking />} />
                        <Route path={RouteNames.YachtBookingRequest} element={<Pages.YachtBookingRequest />} />
                        <Route path={RouteNames.ListingDetail} element={<Pages.ListingDetail />} />
                        <Route path={RouteNames.ApiKeys} element={<Pages.ApiKeys />} />
                        <Route path={RouteNames.AddApi} element={<Pages.AddApi />} />
                        <Route path={RouteNames.PaymentHistory} element={<Pages.PaymentHistory />} />
                    </Route>
                </Route>
            </Routes>
        </BrowserRouter>
    )
}

export const RouteNames = {
    Login: '/login',
    Signup: '/signup',
    VerifyEmail: '/verify-email',
    VerifyPhone: '/verify-phone',
    ForgotPassword: '/forgot-password',
    VerifyForgotPassword: '/verify-forgot-password',
    UpdateForgotPassword: '/update-forgot-password',
    Dashboard: '/dashboard',
    AddListing: '/add-listing',
    EditListing: (id) => `/edit-listing/${id ? id : ':id'}`,
    CarPreview: '/car-preview',
    PrivateJetPreview: '/privatejet-preview',
    LandJetPreview: '/landjet-preview',
    ChauffeursPreview: '/chauffeurs-preview',
    YachtPreview: '/yacht-preview',
    Preview: '/preview',
    AddBusinessDetails: '/add-business-details',
    SubscriptionPlans: '/subscription-plans',
    Contact: '/contact',
    Terms: '/terms-and-conditions',
    HowLandWorks: '/how-land-works',
    WhyLand: '/why-land',
    Services: '/services',
    About: '/about',
    Help: '/help',
    Legal: '/legal',
    PaymentSuccess: '/payment-success',
    MakePayment: '/make-payment',
    MyAccount: '/my-account',
    Copyright: '/copyright',
    PrivacyPolicy: '/privacy',
    DataProtection: '/data-protection',
    Cancellation: '/cancellation',
    Cookies: '/cookies',
    AccountSecurity: '/security',
    ChangePassword: '/security/change-password',
    ChangeEmail: '/my-account/change-email',
    ChangeEmailVerify: '/my-account/verify-email',
    ChangePhone: '/my-account/change-phone',
    ChangeName: '/my-account/change-name',
    ChangePhoneVerify: '/my-account/verify-phone',
    BankDetails: '/bank-details',
    SelectLanguage: '/select-language',
    AddMobile: '/add-mobile',
    Bookings: '/bookings',
    CalendarBooking: '/calendar-booking',
    BookingDetail: '/booking-detail/:id',
    YachtBookingRequest: '/yacht-booking-request',
    ListingDetail: '/listing-detail/:id',
    ApiKeys: '/api-and-keys',
    AddApi: '/add-api',
    PaymentHistory: '/payment-history'
}