
import React from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import InputBox from '../Components/InputBox';
import Button from '../Components/Button';
import { useUpdateForgotPassword } from '../Services/auth';
import useCustomTranslation from '../Hooks/useCustomTranslation';
 
 


export default function Forgot3(){
    const {t} = useCustomTranslation();
    const {handleSubmit, ...rest} = useForm();
    const {state} = useLocation();
    const {mutateAsync} = useUpdateForgotPassword();

    const onSubmit=async(body)=>{
        try{
            let new_body = {
                ...state,
                ...body
            }
            const response = await mutateAsync(new_body);
        }
        catch(e){

        }
    }
    return(
         
        <div  className="wrapper">
        <div  className="main main-bg py-4">

        <div  className="container py-4">
            <div  className="row justify-content-center">
                <div  className="col-lg-4 col-md-6 col-sm-8">
                    <div  className="text-center px-md-3 ">
                        <img src="assets/img/icon/reset-pwd.svg" alt=""/>
                        <h1  className=" text-white pt-4 pb-2 fs-30">
                        {t("Create New Password")}
                        </h1>
                        <p  className="fs-17 text-lightgray">{t("Your new password must be different from your old password.")}</p>

                        <form action=""  className="site-form pt-2" onSubmit={handleSubmit(onSubmit)}>

                        <InputBox
                        type="password"
                        placeholder={"Password"}
                        name="new_password"
                        validations={{
                            minLength : {
                                value : 8,
                                message : t('Password Should be of minimum 8 characters')
                            }
                        }}
                        {...rest}
                        />
                        <InputBox
                        type="password"
                        placeholder={"Confirm Password"}
                        name={"confirm_password"}
                        validations={{
                            validate : {
                                isPasswordMatching : (value)=> value!=rest.getValues().new_password ? t('Passwords should match') : true
                            }
                        }}
                        {...rest}
                        />
                        <Button
                        title={"Reset Password"}
                        />
                            {/* <div  className="form-field mb-3 pwd-group position-relative">
                                <input type="password"  className="pwd-field" placeholder="Password"/>
                                <a href="#!"  className="pwd-visibility text-darkgray position-absolute "><i  className="fa-light fa-eye"></i></a>
                            </div>
                            <div  className="form-field mb-3 pwd-group position-relative">
                                <input type="password"  className="pwd-field" placeholder="Confirm Password"/>
                                <a href="#!"  className="pwd-visibility text-darkgray position-absolute "><i  className="fa-light fa-eye"></i></a>
                                        
                            </div> */}
                            
                            {/* <button  className="custom-btn w-100 rounded-pill">Reset Password</button> */}
                        
                        </form>

                    

                    </div>
                </div>
            </div>
        </div>
            

        </div>
        </div>
         
    )
}
