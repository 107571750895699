import { Suspense, useEffect } from 'react';
import './App.css';
// import '../../public/assets/css/style.css'
// import './index.css';
import { MainRoutes } from './Routes';
import 'react-phone-input-2/lib/style.css'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { SelectedLanguage_ } from './ReduxState/ReducerHelpers';

export const values = {};


function App() {
  const { i18n } = useTranslation();
  const language = useSelector(SelectedLanguage_);
  useEffect(() => {
    // console.log('current______language',i18n.language);
    if (language == "Arabic") {
      i18n.changeLanguage('ar');
      document.getElementsByTagName('html')[0].setAttribute("dir", "rtl");
    }
    else {
      i18n.changeLanguage('en');
      document.getElementsByTagName('html')[0].setAttribute("dir", "ltr");
    }
  }, [language]);
  return (
    <MainRoutes />
  )
}

export default App;
