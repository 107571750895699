import { lazy } from "react";

export const Landing = lazy(()=> import('./Landing'));
export const Signup = lazy(()=> import('./Signup'));
export const Login = lazy(()=> import('./Login'));
export const AddBusinessDetails = lazy(()=> import('./BusinessDetails'));
export const PaymentSuccess = lazy(()=> import('./PaymentDone'));
export const AddListing = lazy(()=> import('./AddListing'));
export const Listing = lazy(()=> import('./Listing.jsx'));
export const EditListing = lazy(()=> import('./EditListing'));
export const VerifyRegisterEmail = lazy(()=> import('./VerifyRegisterEmail'));
export const VerifyRegisterPhone = lazy(()=> import('./VerifyRegisterPhone'));
export const ForgotPassword = lazy(()=> import('./Forgot1'));
export const VerifyForgotPassword = lazy(()=> import('./Forgot2'));
export const UpdateForgotPassword = lazy(()=> import('./Forgot3'));
export const LandjetPreview = lazy(()=> import('./LandjetPreview'));
export const CarPreview = lazy(()=> import('./CarPreview'));
export const YachtPreview = lazy(()=> import('./YachtPreview'));
export const PrivateJetPreview = lazy(()=> import('./PrivateJetPreview'));
export const ChaffeursPreview = lazy(()=> import('./ChaffeursPreview'));
export const PreviewListing = lazy(()=> import('./Preview'));
export const BusinessDetails = lazy(()=> import('./BusinessDetails'));
export const SubscriptionPans = lazy(()=> import('./SubscriptionPlan'));
export const Contact = lazy(()=> import('./Contact'));
export const HelpPage = lazy(()=> import('./Help'));
export const TermsConditions = lazy(()=> import('./Terms'));
export const HowLandWorks = lazy(()=> import('./HowLandWorks'));
export const WhyLand = lazy(()=> import('./WhyLand'));
export const Services = lazy(()=> import('./Services'));
export const About = lazy(()=> import('./About'));
export const Legal = lazy(()=> import('./Legal'));
export const MakePayment = lazy(()=> import('./MakePayment'));
export const MyAccount = lazy(()=> import('./MyAccount'));
export const Copyright = lazy(()=> import('./Copyright'));
export const Cookies = lazy(()=> import('./Cookies'));
export const CancellationPolicy = lazy(()=> import('./CancellationPolicy'));
export const DataProtection = lazy(()=> import('./DataProtection'));
export const PrivacyPolicy = lazy(()=> import('./PrivacyPolicy'));
export const AccountSecurity = lazy(()=> import('./Security'));
export const UpdateEmail = lazy(()=> import('./ChangeEmail'));
export const UpdateEmailVerifyOTP =lazy(()=> import('./ChangeEmail2fa'));
export const UpdatePassword = lazy(()=> import('./SecurityEdit'));
export const BankDetails = lazy(()=> import('./BankDetails.jsx'));
export const SelectLanguage = lazy(()=> import('./SelectLanguage.jsx'));
export const AddMobile = lazy(()=> import('./AddMobile'));
export const Bookings = lazy(()=> import('./BookingUpcoming.jsx'));
export const CalendarBooking = lazy(()=> import('./CalendarBooking.jsx'));
export const BookingDeatils = lazy(()=> import('./BookingDetail.jsx'));
export const YachtBookingRequest = lazy(()=> import('./YachtBookingRequest.jsx'));
export const ListingDetail = lazy(()=> import('./ListingDetail.jsx'));
export const ApiKeys = lazy(()=> import('./ApiKeys.jsx'));
export const AddApi = lazy(()=> import('./AddApi.jsx'));
export const PaymentHistory = lazy(()=> import('./PaymentHistory.jsx'));

// export const 