import React, { useState } from "react";
import ReactGoogleAutocomplete from "react-google-autocomplete";
import { Controller, useController, useForm } from "react-hook-form";
import useCustomTranslation from "../Hooks/useCustomTranslation";
import {BsEyeSlash} from 'react-icons/bs';


export default function InputBox({
    placeholder,
    type, 
    register, 
    name, 
    errors, 
    onChange, 
    dropdownData, 
    multiline,
    formState,
    required=true,
    inputClass,
    containerClass,
    onSelect,
    children,
    validations,
    value,
    price_field,
    message,
    label,...rest}){

    const {t} = useCustomTranslation();
  const [view1, setView1] = useState(false);

    if(children){
        return(
            <div className={containerClass!==undefined ? containerClass : "form-field mb-4"}>
            <Controller control={rest.control} name={name}
            rules={validations ? {...validations} : {
                required : required
            }}
            render={({field})=>{
                return(
                    <div>
                        {
                        children({...field})
                        }
                        
                    </div>
                )
            }}
            />
            {
                formState && formState?.errors && formState?.errors[name] && 
                <p style={{color : 'red'}}>
                    {formState?.errors[name].message || t(message) || t("This field is required")}
                </p>
            }
            </div>
        )
    }
    const address_finder=(addr, type)=>{
        let arr = addr.find((item)=> item.types.includes(type));
        return arr ? arr.long_name : "";
    }
    if(type=="location"){
        return(
            <div className={containerClass!==undefined ? containerClass : "form-field mb-4"}>
            {
            label && <label for=""  className="pb-2 medium fs-16 text-lightgray">{t(`${label}`)}</label>
            }
            <ReactGoogleAutocomplete
            defaultValue={value}
            apiKey={process.env.REACT_APP_GOOGLE_LOCATION_KEY}
            options={{
                types : []
            }}
            onPlaceSelected={(place) => {
                let locality, address, city, state, country, zipcode = "";
                let address_list = place.address_components;

                locality = address_finder(address_list,"sublocality");
                city = address_finder(address_list,"locality");
                state = address_finder(address_list,"administrative_area_level_1");
                country = address_finder(address_list,"country");
                zipcode = address_finder(address_list,"postal_code");
                address = place.formatted_address;

                const location = place.formatted_address;
                const latitude = place.geometry.location.lat();
                const longitude = place.geometry.location.lng();
                // rest?.setValue('location',location);
                rest?.setValue('latitude',latitude.toFixed(5));
                rest?.setValue('longitude',longitude.toFixed(5));
                rest?.setValue('locality',locality || city);
                rest?.setValue('city',city);
                rest?.setValue('state',state);
                rest?.setValue('country',country);
                rest?.setValue('zipcode',zipcode);
                rest?.setValue('address',location);
            }}
            className='locationfield'
            placeholder={t('Enter Location')}
            // style={{marginBottom : 20, height : 40, background}}
            />
            </div>
        )
    }
    return(
        <div className={containerClass!==undefined ? containerClass : "form-field mb-4"}>

        {
            label && <label htmlFor=""  className="pb-2 medium fs-16 text-lightgray">{t(`${label}`)} {name.includes('features')?("("+(rest.watch(name)?.length || 0) + "/100)") :''}</label>
        }
        {
            dropdownData ?
            <select {...register(name,{required})} onChange={onSelect} className={inputClass}>
                <option value={''}> {t("Select")} </option>
                {
                    dropdownData?.map((item,index)=>{
                        return(
                            <option value={item.value} key={index}>{item.label}</option>
                        )
                    })
                }
            </select>
            :
            multiline 
            ?
            <textarea 
            cols="10" 
            rows="4" 
            // id="the-textarea" 
            // maxlength="300" 
            placeholder={t(`${placeholder}`)}
            {...register(name,{required})} 
            autofocus="">

            </textarea>
            :
            <div className={`input-container ${inputClass} d-flex align-items-center`}>
            <input type={view1? 'text':type} 
            // className={inputClass}
            placeholder={t(`${placeholder}`)} 
            className={"text-lightgray datetime-input"}
            value={value}
            maxLength={name.includes('features')?100: ''}
            style={{width : 100+'%', backgroundColor : 'black', borderWidth : 0}}
            onChange={(e)=>onChange?.(e.target.value)} 
            {...register?.(name,{required, ...validations})} 
            />
            {price_field && <div>AED</div>}
            {type=="password" && 
            (
                !view1?
                <BsEyeSlash 
                onClick={()=>{
                    setView1((prev) => !prev);
                }}
                // color="white" 
                size={20} />
                :
                <img 
                onClick={() => {
                    setView1((prev) => !prev);
                }}
                className="show-pointer" style={{width: '20px', height: '15px'}} 
                src="/assets/img/eye.png"/>
            )
            }
            </div>
        }
        {
            formState && formState?.errors && formState?.errors[name] && 
            <p style={{color : 'red', fontSize : 14, textAlign : 'left'}}>
                {formState?.errors[name].message || t(message) || t("This field is required")}
            </p>
        }
        </div>
    )
}