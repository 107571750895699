
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useLocation } from 'react-router-dom';
import { useResendEmailOTP, useVerifyForgotEmail } from '../Services/auth';
import OTPInput from 'react-otp-input';
import Button from '../Components/Button';
import useCustomTranslation from '../Hooks/useCustomTranslation';
 
 


export default function Forgot2(){
    const {t} = useCustomTranslation();
    const {handleSubmit} = useForm();
    const {state} = useLocation();
    const {mutateAsync : resendOtp} = useResendEmailOTP();
    const [otp, setOtp] = useState('');

    const {mutateAsync} = useVerifyForgotEmail();

    const onSubmit=async(e)=>{
        e.preventDefault();
        try{
            let body = {
                code : otp,
                email : state.identity
            };
            await mutateAsync(body);
        }
        catch(e){

        }
    }
    const handleResendOtp=async()=>{
        try{
            await resendOtp({email : state?.identity, usage : 'Forgot'});
        }
        catch(e){

        }
    }
    return(
         
        <div  className="wrapper">
        <div  className="main main-bg py-4">

        <div  className="container py-4">
            <div  className="row justify-content-center">
                <div  className="col-lg-4 col-md-6 col-sm-8">
                    <div  className="text-center ">
                        <img src="assets/img/icon/email-verification.svg" alt=""/>
                        <h1  className=" text-white py-3 fs-30">
                        {state?.msg?.includes('phone')? t("Phone Verification") : t("Email Verification")}
                        </h1>
                        <p  className="fs-17 text-lightgray">{t("We have sent a 6 digit code to your")} <span  className="bold">{state?.msg?.includes('phone')? t("phone") : t("email")} {state?.identity}</span></p>

                        <form action=""  className="site-form pt-2" onSubmit={onSubmit}>
                            <div  className="form-field pb-4 otp-field d-flex gap-2 gap-lg-3 justify-content-center">
                                    {/* <input type="number"  className=""/>
                                    <input type="number"  className=""/>
                                    <input type="number"  className=""/>
                                    <input type="number"  className=""/>
                                    <input type="number"  className=""/>
                                    <input type="number"  className=""/> */}
                                <OTPInput
                                value={otp}
                                onChange={setOtp}
                                numInputs={6}
                                renderSeparator={<span>-</span>}
                                renderInput={(props) => <input {...props} type='number' />}
                                />
                            </div>
                            <Button
                            title={"Verify"}
                            />
                            {/* <button  className="custom-btn w-100 rounded-pill">Verify</button> */}
                            
                        </form>
                        <div onClick={handleResendOtp} className="d-block text-lightgray pt-4 pb-4" style={{cursor : 'pointer'}}>{t("Resend Code")}</div>
                        {/* <p>Did not receive the email? Check your email spam, or <a href="#"  className="text-red">try your phone number</a></p> */}

                        


                    </div>
                </div>
            </div>
        </div>
            

        </div>
        </div>
         
    )
}
