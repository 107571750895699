import {toast} from 'react-toastify';


const Error = (message)=> {
    message && toast(message,{position : 'top-right', type : 'error'});
}

const Success = (message)=>{
    message && toast(message,{position : 'top-right',type : 'success'});
}

export default {
    Error,
    Success
}