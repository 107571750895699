import axios from "axios";
import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Endpoints } from "../Configs/index";
import { AccessToken_, SelectedLanguage_ } from "../ReduxState/ReducerHelpers";
import CustomToast from "../Utils/CustomToast";
import { useNavigate } from "react-router-dom";
import { RouteNames } from "../Routes";
import { clearData } from "../ReduxState/Slices/UserSlice";


// const refreshToken=()=>{

// }


export default function useFetch(){
    const token = useSelector(AccessToken_);
    const language = useSelector(SelectedLanguage_);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    let lng = language=="English" ? "/en" : '/ar';
    const fetchData = async({endpoint, body, method})=>{
        try{
            let configs = {
                method : 'GET',
                url : Endpoints.APIBaseUrl + lng +  '/api/v1/' + endpoint,
                headers : {
                    // 'Content-Type' : 'application/json'
                }
            }
            if(body){
                configs.data = body;
            }
            if(method){
                configs.method = method;
            }
            if(token){
                configs.headers = {
                    ...configs.header,
                    Authorization : 'Bearer ' + token,
                };
            }
            let response = await axios(configs);
            // if(response.status==401){
            //     return refreshToken({token});
            // }
            if(typeof(response?.data?.status)==='boolean' && response?.data?.status===false){
                if(response?.data?.message){

                    CustomToast.Error(response.data.message);
                    if(response?.data?.message=="Given token not valid for any token type"
                    || response?.data?.message=="Your account is deleted"){
                        dispatch(clearData());
                        navigate(RouteNames.Login, {replace: true});   
                    }
                }
                throw response.data;
            }
            else{
                if(response?.data?.user?.user_type!="customer"){
                    CustomToast.Success(response?.data?.message);
                }
                return response.data;
            }
        }
        catch(e){
            if(e?.response){
                CustomToast.Error(e.response?.data?.message);
                if(e.response?.data?.message=="Given token not valid for any token type"
                || e.response?.data?.message=="Your account is deleted"){
                    dispatch(clearData());
                    navigate(RouteNames.Login, {replace: true});   
                }
            }
            throw e;
        }
    }

    return {fetchData};
};